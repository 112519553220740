import "./rangeList.css";
import { frFR, DataGrid } from "@material-ui/data-grid";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRange, getRanges, removeAsset, updateRange } from "../../redux/apiRequests";
import {
  Button,
  Container,
  Row,
  Spacer,
  Text,
  Modal,
  Switch,
  Progress,
} from "@nextui-org/react";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetRange } from "../../redux/rangeRedux";
import { resetProduct } from "../../redux/productRedux";
import { resetCategory } from "../../redux/categoryRedux";

export default function RangeList() {
  const dispatch = useDispatch();
  const ranges = useSelector((state) => state.range.ranges);
  const [visible, setVisible] = useState(false);
  const [range, setRange] = useState({});
  const [loading, setLoading] = useState(false);
  const isLoading = useSelector((state) => state.range.isFetching);
  const handler = () => setVisible(true);
  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const closeHandler = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch(resetRange());
    dispatch(resetCategory());
    dispatch(resetProduct());
  }, []);

  useEffect(() => {
    getRanges(dispatch);
  }, [dispatch]);

  const handleDelete = async () => {
    setLoading(true);
    await removeAsset(range.image, dispatch)
    deleteRange(range._id, dispatch)
      .then(() => {
        setLoading(false);
        closeHandler();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleUpdate = (range) => {
    updateRange(range._id, { published: !range.published }, dispatch).then(
      () => {
        !range.published === false &&
          toast.warning("La gamme n'est plus en ligne", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            transition: Flip,
          });

        !range.published === true &&
          toast.success("La gamme est maintenant en ligne", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            transition: Flip,
          });
      }
    );
  };

  const columns = [
    {
      field: "image",
      headerName: "Gamme",
      width: 500,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.image && (
              <img
                className="productListImg"
                src={`${process.env.REACT_APP_SITE_URL}/products/${params.row.image}`}
                alt=""
              />
            )}

            {!params.row.image && <div className="productListImg"></div>}
            {params.row.name}
          </div>
        );
      },
    },
    {
      field: "published",
      headerName: "En ligne",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <Switch
              color="success"
              checked={params.row.published}
              onChange={() => {
                handleUpdate({
                  _id: params.row._id,
                  name: params.row.name,
                  published: params.row.published,
                });
              }}
            />
          </>
        );
      },
    },
    {
      field: "updatedBy",
      headerName: "Dernière Modif.",
      width: 220,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.updatedBy && params.row.updatedBy.firstname}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 320,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/range/" + params.row._id}>
              <Button auto color="secondary" rounded flat>
                Modifier
              </Button>
            </Link>

            <Button
              light
              color="error"
              auto
              onClick={() => {
                setRange({
                  _id: params.row._id,
                  name: params.row.name,
                  published: params.row.published,
                  image: params.row.image,
                });
                handler();
              }}
            >
              Supp
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Container className="rangeList">
      <Spacer y={1} />
      <Row align="center" justify="space-between">
        <Text h1>Gammes</Text>
        <Link to="/newrange">
          <Button auto>Ajouter une gamme</Button>
        </Link>
      </Row>
      <Spacer y={1} />
      {isLoading && (
        <Progress
          indeterminated
          color="secondary"
          status="secondary"
          size="mini"
        />
      )}
      <DataGrid
        localeText={frFR.props.MuiDataGrid.localeText}
        rows={ranges}
        disableSelectionOnClick
        columns={columns}
        getRowId={(row) => row._id}
        pageSize={8}
        columnBuffer={8}
        checkboxSelection
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          setSelectedRowsData(selectedIDs);
        }}
      />

      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Supprimer une gamme
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Text>
            Êtes vous sûr de vouloir supprimer la gamme{" "}
            <span style={{ fontWeight: "bold" }}>{range.name}</span> ?{" "}
          </Text>
          <Text color="warning" h6>
            Certains produits liés peuvent se retrouver sans gamme.
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat onClick={closeHandler}>
            Annuler
          </Button>
          <Button
            auto
            {...(loading && { loading })}
            onClick={handleDelete}
            color="error"
            loaderType="spinner"
          >
            Supprimer quand même
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </Container>
  );
}
