import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    resetOrder: (state) => {
      state._id = null;
      state.orders = [];
    },
    getOrderStart: (state) => {
      state.isFetching = true;
    },
    getOrderSuccess: (state, action) => {
      state.isFetching = false;
      state.orders = action.payload;
    },
    getOrderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    updateOrderStart: (state) => {
      state.isFetching = true;
    },
    updateOrderSuccess: (state, action) => {
      state.isFetching = false;
      const index = state.orders.findIndex((order) => order._id === action.payload._id);
      state.orders[index] = action.payload;
    },
    updateOrderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  resetOrder,
  getOrderStart,
  getOrderSuccess,
  getOrderFailure,
  updateOrderStart,
  updateOrderSuccess,
  updateOrderFailure,
} = orderSlice.actions;

export default orderSlice.reducer;
