import { createSlice } from "@reduxjs/toolkit";

export const storeSlice = createSlice({
  name: "store",
  initialState: {
    stores: [],
    isFetching: false,
    error: false,
    isDuplicate: false,
  },
  reducers: {
    resetStore: (state) => {
      state.isFetching = false;
      state.error = false;
      state.isDuplicate = false;
    },
    //GET ALL
    getStoreStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getStoreSuccess: (state, action) => {
      state.isFetching = false;
      state.stores = action.payload;
    },
    getStoreFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    addStoreDuplicateFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.isDuplicate = true;
    },
    //DELETE
    deleteStoreStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteStoreSuccess: (state, action) => {
      state.isFetching = false;
      state.stores.splice(
        state.stores.findIndex((item) => item._id === action.payload),
        1
      );
    },
    deleteStoreFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE
    updateStoreStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateStoreSuccess: (state, action) => {
      state.isFetching = false;
      state.stores[
        state.stores.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.product;
    },
    updateStoreFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    addStoreStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addStoreSuccess: (state, action) => {
      state.isFetching = false;
      state.stores.push(action.payload);
    },
    addStoreFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  resetStore,
  getStoreStart,
  getStoreSuccess,
  getStoreFailure,
  deleteStoreStart,
  deleteStoreSuccess,
  deleteStoreFailure,
  updateStoreStart,
  updateStoreSuccess,
  updateStoreFailure,
  addStoreStart,
  addStoreSuccess,
  addStoreFailure,
  addStoreDuplicateFailure,
} = storeSlice.actions;

export default storeSlice.reducer;
