import "./Login.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/apiRequests";
import { Container, Text, Input, Spacer, Button } from "@nextui-org/react";
import { resetError, resetUserSession } from "../../redux/userRedux";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [helper, setHelper] = useState({
    text: "",
    color: "",
  });
  const [passhelper, setPasshelper] = useState({
    text: "",
    color: "",
  });
  const [loading, setLoading] = useState(false);
  const getUserSession = useSelector((state) => state.user);

  const handleClick = (e) => {
    dispatch(resetUserSession());
    e.preventDefault();
    if (username === "") {
      setHelper({
        text: "Nom d'utilisateur requis",
        color: "error",
      });
    }

    if (!password) {
      setPasshelper({
        text: "Mot de passe requis",
        color: "error",
      });
    }
    if (username === "" || password === "") {
      return;
    }
    setLoading(true);
    login(dispatch, { username, password })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setHelper({
      text: "",
      color: "default",
    });
  }, [username]);

  useEffect(() => {
    setPasshelper({
      text: "",
      color: "",
    });
  }, [password]);

  useEffect(() => {
    if (
      user.currentUser &&
      user.currentUser.role !== "user" &&
      user.currentUser.active
    ) {
      location.reload();
      return false;
    }
  }, [user]);

  useEffect(() => {
    dispatch(resetError());
  }, []);

  return (
    <Container
      display="flex"
      justify="center"
      align="center"
      direction="column"
      style={{ height: "98vh" }}
    >
      <Container
        display="flex"
        justify="center"
        align="center"
        direction="column"
        className="login-wrapper"
      >
        <Text h3>Login</Text>
        <Spacer y={2} />
        {user.error && user.error !== "401" && user.error !== "45" && (
          <Text color="error">Nom d'utilisateur ou mot de passe invalide</Text>
        )}
        {user.error === "401" &&
          user.role !== "admin" &&
          user.role !== "superadmin" && (
            <Text color="error">
              Vous n'êtes pas autorisé à accéder à l'administration
            </Text>
          )}
        {user.error === "45" &&
          !user.currentUser.active &&
          user.role !== "user" && (
            <Text color="error">
              Votre compte a été suspendue. Contactez les admins pour en savoir
              plus
            </Text>
          )}
        {getUserSession && getUserSession.status === "403" && (
          <Text style={{
            marginBottom: "2.5rem"
          }} color="error">
            Votre session a expiré ! Veuillez vous reconnecter
          </Text>
        )}
        {(user.error || (!user.error && !user.isAdmin)) && <Spacer y={2} />}
        <Input
          type="text"
          labelPlaceholder="Nom d'utilisateur"
          shadow={false}
          bordered
          color={helper.color}
          helperColor={helper.color}
          helperText={helper.text}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Spacer y={2.5} />
        <Input.Password
          labelPlaceholder="Mot de passe"
          bordered
          shadow={false}
          color={passhelper.color}
          helperColor={passhelper.color}
          helperText={passhelper.text}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Spacer y={1.6} />
        <Button {...(loading && { loading })} onClick={handleClick}>
          Connexion
        </Button>
      </Container>
    </Container>
  );
};

export default Login;
