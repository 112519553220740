import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "users",
  initialState: {
    users: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    resetUsers: (state) => {
      state.users = null;
      state.isFetching = false;
      state.error = false;
    },
    getUsersStart: (state) => {
      state.isFetching = true;
    },
    getUsersSuccess: (state, action) => {
      state.isFetching = false;
      state.users = action.payload;
    },
    getUsersFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    updateUsersStart: (state) => {
      state.isFetching = true;
    },
    updateUsersSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateUsersFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    deleteUsersStart: (state) => {
      state.isFetching = true;
    },
    deleteUsersSuccess: (state, action) => {
      state.isFetching = false;
      state.users = action.payload;
    },
    deleteUsersFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getUsersStart,
  getUsersSuccess,
  getUsersFailure,
  updateUsersStart,
  updateUsersSuccess,
  updateUsersFailure,
  deleteUsersStart,
  deleteUsersSuccess,
  deleteUsersFailure,
  resetUsers,
  logout,
} = userSlice.actions;
export default userSlice.reducer;
