import { Text } from "@nextui-org/react";
import "./notAvailable.css";

const NotAvailable = () => (
  <div className="notAvailable">
    <Text>Cette fonctionnalité n'est pas encore disponible</Text>
  </div>
);

export default NotAvailable;
