import './viewOrder.css';
import { Text, Row, Col, Container, Button, Modal } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { userRequest } from '../../requestMethods';
import { ToastContainer, toast, Flip } from "react-toastify";
import { updateOrder } from '../../redux/apiRequests';
import { useDispatch } from 'react-redux';
const dayjs = require('dayjs');

const ViewOrder = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const orderId = location.pathname.split('/')[3];
    const [visible, setVisible] = useState(false);
    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState('');
    const handler = () => setVisible(true);

    const closeHandler = () => {
        setVisible(false);
    };

    const getOrder = async () => {
        try {
            const res = await userRequest.get(`orders/find/order/${orderId}`);
            setOrder(res.data);
        } catch { }
    };

    useEffect(() => {
        getOrder();
    }, []);

    const handleUpdate = () => {
        setLoading(true);
        action === "cancel" && updateOrder(
            order._id,
            { status: "cancelled" },
            dispatch
        ).then(() => {
            toast.success("La commmande a été annulée !", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                transition: Flip,
            });
            closeHandler();
            setLoading(false);
            getOrder();
        });

        action === "process" && updateOrder(
            order._id,
            { status: "processing" },
            dispatch
        ).then(() => {
            toast.info("La commande est en cours de traitemant", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                transition: Flip,
            });
            closeHandler();
            setLoading(false);
            getOrder();
        });

        action === "deliver" && updateOrder(
            order._id,
            { status: "in_transit" },
            dispatch
        ).then(() => {
            toast.info("La commande est en cours de livraison", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                transition: Flip,
            });
            closeHandler();
            setLoading(false);
            getOrder();
        });

        action === "complete" && updateOrder(
            order._id,
            { status: "delivered" },
            dispatch
        ).then(() => {
            toast.success("La commande est livrée", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                transition: Flip,
            });
            closeHandler();
            setLoading(false);
            getOrder();
        });
    };

    return (
        <Container className="viewOrder">
            <Text h1 style={{ margin: '1rem' }}>
                Commande  {" "}{order?.orderNumber}
            </Text>
            <Text h4 style={{ margin: '1rem' }}>
                Date de commande:  {" "}
                {dayjs(order?.updatedAt).format("DD/MM/YYYY")}
            </Text>

            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <Text h3>
                                Détails de la commande
                            </Text>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className=" text-primary">
                                        <th>
                                            Produit
                                        </th>
                                        <th>
                                            Quantité
                                        </th>
                                        <th>
                                            Prix
                                        </th>
                                    </thead>
                                    <tbody>
                                        {order?.products?.map(product => (
                                            <tr key={product.productId._id}>
                                                <td>
                                                    {product.productId.name}
                                                </td>
                                                <td>
                                                    {product.quantity}
                                                </td>
                                                <td>
                                                    -
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <Row style={{ justifyContent: 'space-between', marginTop: "2rem" }}>
                                    <Col>
                                        <Text weight="600">
                                            Total:{' '}
                                            <span style={{ fontWeight: 400 }}>
                                                {order?.products?.length}
                                            </span>
                                        </Text>
                                    </Col>

                                    <Col style={{ textAlign: 'end' }}>
                                        <Text weight="600">
                                            Statut:{' '}
                                            <Text
                                                color={
                                                    order?.status === "cancelled"
                                                        ? "error"
                                                        : order?.status === "pending"
                                                            ? "warning"
                                                            : (order?.status === "processing" || order?.status === "in_transit")
                                                                ? "primary"
                                                                : "success"
                                                }
                                                style={{ fontWeight: 400 }}>
                                                {order?.status === 'pending' && 'En attente'}
                                                {order?.status === 'processing' && 'En cours de traitement'}
                                                {order?.status === 'in_transit' && 'En cours livraison'}
                                                {order?.status === 'delivered' && 'Livré'}
                                                {order?.status === 'cancelled' && 'Annulé'}
                                            </Text>
                                        </Text>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <Text h3>Informations du client</Text>
                        </div>
                        <Row className="card-body">
                            <Col style={{ marginBottom: '1rem' }}>
                                <Text weight="bold">
                                    Nom:{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        {order?.userId?.lastname} {order?.userId?.firstname}
                                    </span>
                                </Text>
                                <Text weight="bold">
                                    Email:{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        {order?.userId?.email}
                                    </span>
                                </Text>
                            </Col>
                        </Row>
                        <div className="card-header">
                            <Text h4>Adresse renseignée pour la commande</Text>
                        </div>
                        <Row className="card-body" style={{ marginBottom: '0' }}>
                            <Col style={{ marginBottom: '1rem' }}>
                                <Text weight="bold">
                                    Rue:{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        {order?.address?.street}
                                    </span>
                                </Text>
                                <Text weight="bold">
                                    Ville:{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        {order?.address?.city}
                                    </span>
                                </Text>
                            </Col>
                            <Col style={{ marginBottom: '1rem' }}>
                                <Text weight="bold">
                                    Etat:{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        {order?.address?.state}
                                    </span>
                                </Text>
                                <Text weight="bold">
                                    Code postal:{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        {order?.address?.zip}
                                    </span>
                                </Text>
                            </Col>
                        </Row>
                        <Row className="card-body">
                            <Col style={{ marginTop: '-1rem' }}>
                                <Text weight="bold">
                                    Pays:{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        {order?.address?.country}
                                    </span>
                                </Text>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "2.5rem" }}>
                            {(order?.status === 'processing' || order?.status === 'processing' || order?.status === 'in_transit') && (
                                <Button
                                    color="error"
                                    style={{ marginRight: '1rem' }}
                                    onClick={() => {
                                        setAction("cancel");
                                        handler();
                                    }}
                                >
                                    Annuler la commande
                                </Button>
                            )}
                            {order?.status === 'pending' && (
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setAction("process");
                                        handler();
                                    }}
                                >
                                    Traiter la commande
                                </Button>
                            )}
                            {order?.status === 'processing' && (
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setAction("deliver");
                                        handler();
                                    }}
                                >
                                    Livrer la commande
                                </Button>
                            )}
                            {order?.status === 'in_transit' && (
                                <Button
                                    color="success"
                                    onClick={() => {
                                        setAction("complete");
                                        handler();
                                    }}
                                >
                                    Marquer comme livré
                                </Button>
                            )}
                        </Row>
                    </div>
                </div>
            </div>

            <Modal
                closeButton
                aria-labelledby="modal-title"
                open={visible}
                onClose={closeHandler}
            >
                <Modal.Header>

                    <Text id="modal-title" size={18}>
                        {action === "cancel" && "Annuler une commande"}
                        {action === "process" && "Traiter une commande"}
                        {action === "deliver" && "Livrer une commande"}
                        {action === "complete" && "Confirmer une commande"}
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <Text>
                        {action === "cancel" && <span>Voulez-vous vraiment annuler la commande`{" "}</span>}
                        {action === "process" && <span>
                            En confirmant cette action, vous traitez la commande cliente numéro {" "}
                        </span>}
                        {action === "deliver" && <span>
                            En confirmant cette action, vous livrez la commande numéro {" "}
                        </span>}
                        {action === "complete" && <span>
                            La commande numéro {" "}
                        </span>}
                        <span style={{ fontWeight: "bold" }}>{order?.orderNumber}</span>
                        {action === "process" && <span> {" "}
                            et sera disponible pour la livraison dès que vous aurez valider le paiement. (Echanges whatsapp etc..)
                        </span>}
                        {action === "deliver" && <span> {" "}
                            au client.
                        </span>}
                        {action === "complete" && <span> {" "}
                            sera marquée comme livrée.
                        </span>}
                    </Text>
                </Modal.Body>
                <Modal.Footer>
                    <Button auto flat onClick={closeHandler}>
                        Retour
                    </Button>
                    <Button
                        auto
                        {...(loading && { loading })}
                        onClick={handleUpdate}
                        color="error"
                        loaderType="spinner"
                    >
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </Container>
    );
}

export default ViewOrder
