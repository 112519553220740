import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./composer.css";

export default function Composer({ editorState, onStateChange }) {
  return (
    <Editor
      toolbar={{
        fontFamily: {
          options: []
        },
      }}
      editorState={editorState}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorWrapper"
      onEditorStateChange={onStateChange}
      localization={{
        locale: 'fr',
      }}
    />
  );
}
