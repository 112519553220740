import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import "./App.css";
import Home from "./pages/home/Home";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import UserList from "./pages/userList/UserList";
import ProductList from "./pages/productList/ProductList";
import Product from "./pages/product/Product";
import NewProduct from "./pages/newProduct/NewProduct";
import NotFound from "./pages/404/404";
import Login from "./pages/login/Login";
import RangeList from "./pages/rangeList/RangeList";
import CategoryList from "./pages/categoryList/CategoryList";
import NewCategory from "./pages/newCategory/NewCategory";
import NewRange from "./pages/newRange/NewRange";
import Settings from "./pages/settings/Settings";
import NotAvailable from "./pages/NotAvailable/NotAvailable";
import Sales from "./pages/sales/Sales";
import OrderList from "./pages/orderList/OrderList";
import ViewOrder from "./pages/ViewOrder/ViewOrder";

function App() {
  const admin =
    JSON.parse(localStorage.getItem("persist:root")) !== null &&
      JSON.parse(localStorage.getItem("persist:root")).user &&
      JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
        .currentUser &&
      JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
        .currentUser.role
      ? (JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
        .currentUser.role === "admin" ||
        JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.role === "superadmin") &&
      JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
        .currentUser.active
      : false;

  return (
    <Router>
      <Switch>
        <Route path="/login">{admin ? <Redirect to="/" /> : <Login />}</Route>
        {admin && (
          <>
            <Topbar />
            <div className="main-container">
              <Sidebar />
              <Switch>
                <Route path="/404">
                  <NotFound />
                </Route>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/sales">
                  <Sales />
                </Route>
                <Route path="/orders/order/:orderId">
                  <ViewOrder />
                </Route>
                <Route path="/orders" exact>
                  <OrderList />
                </Route>
                <Route path="/users">
                  <UserList />
                </Route>
                <Route path="/manage/users" exact>
                  <UserList manager="admin" />
                </Route>
                <Route path="/products">
                  <ProductList />
                </Route>
                <Route path="/categories">
                  <CategoryList />
                </Route>
                <Route path="/ranges">
                  <RangeList />
                </Route>
                <Route path="/product/:productId">
                  <NewProduct />
                </Route>
                <Route path="/newproduct">
                  <NewProduct />
                </Route>
                <Route path="/category/:categoryId">
                  <NewCategory />
                </Route>
                <Route path="/newcategory">
                  <NewCategory />
                </Route>
                <Route path="/range/:rangeId">
                  <NewRange />
                </Route>
                <Route path="/newrange">
                  <NewRange />
                </Route>
                <Route path="/settings">
                  <Settings />
                </Route>
                <Route path="/manage/users/newuser">
                  <Settings />
                </Route>
                <Route path="/manage/users/user/:userId">
                  <Settings />
                </Route>
                <Route path="/manage/users/admin/:userId">
                  <Settings />
                </Route>
                <Route path="/notavailable">
                  <NotAvailable />
                </Route>
                <Route path="**">
                  <NotFound />
                </Route>
              </Switch>
            </div>
          </>
        )}
        <Route>{!admin && <Redirect to="/login" />}</Route>
      </Switch>
    </Router>
  );
}

export default App;