import React, { useEffect } from "react";
import "./topbar.css";
import { RiSettings5Line } from "react-icons/ri";
import { Row, Avatar, Grid, Text, Tooltip, Card } from "@nextui-org/react";
import { BiBell, BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/userRedux";
import { getUser } from "../../redux/apiRequests";

export default function Topbar() {
  const [openDropdown, setopenDropdown] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handleClick = (e) => {
    dispatch(logout());
  };

  const handleDropdownChange = (e) => {
    setopenDropdown(!openDropdown);
  };

  useEffect(() => {
    getUser(user.currentUser._id, dispatch);
  }, []);

  useEffect(async () => {
    if (!user.currentUser || (user && user.status === "403")) {
      await handleClick();
      setTimeout(() => {
        location.reload();
        return false;
      }, 1000);
    }
  }, [user]);

  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <Text>Laboratoire Luxe Cosmetique</Text>
        </div>
        <div className="topRight">
          <Grid.Container gap={1}>
            <Grid className="topbarIconContainer">
              <Tooltip
                content={"Cette option n'est pas disponible"}
                trigger="click"
                placement="left"
              >
                <Avatar
                  color="transparent"
                  textColor="white"
                  icon={
                    <BiBell
                      size={20}
                      style={{ cursor: "pointer", color: "#aeaeae" }}
                    />
                  }
                />
              </Tooltip>
            </Grid>
            <Grid>
              <Link to="/settings" className="link">
                <Avatar
                  color="transparent"
                  textColor="white"
                  icon={
                    <RiSettings5Line size={20} style={{ cursor: "pointer" }} />
                  }
                />
              </Link>
            </Grid>
            <Grid>
              <Row
                align="center"
                justify="center"
                onClick="openDropdown"
                onClick={handleDropdownChange}
              >
                <Avatar squared text="Admin" style={{ cursor: "pointer" }} />
                <BiChevronDown size={20} style={{ cursor: "pointer" }} />
              </Row>
            </Grid>
            {openDropdown && (
              <Card className="card-dropdown" width="160px">
                <span onClick={handleClick}>Se déconnecter</span>
              </Card>
            )}
          </Grid.Container>
        </div>
      </div>
    </div>
  );
}
