import "./userList.css";
import { frFR, DataGrid } from "@material-ui/data-grid";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";
import {
  getAdminUsers,
  getUsers,
  updateUsersUser,
} from "../../redux/apiRequests";
import {
  Button,
  Container,
  Row,
  Spacer,
  Text,
  Modal,
  Progress,
} from "@nextui-org/react";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function UserList({ manager }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const isLoading = useSelector((state) => state.users.isFetching);
  const handler = () => setVisible(true);

  const closeHandler = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (manager) {
      getAdminUsers(dispatch);
    } else {
      getUsers(dispatch);
    }
  }, [dispatch, location]);

  const handle = () => {
    setLoading(true);
    updateUsersUser(
      user._id,
      {
        active: !user.active,
      },
      dispatch
    )
      .then(() => {
        setLoading(false);
        closeHandler();
        user.active && user.role !== "user" ?
          history.push("/manage/users") : history.push("/users")
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const columns = manager
    ? [
      {
        field: "name",
        headerName: "Utilisateur",
        width: 300,
        renderCell: (params) => {
          return (
            <div className="userListUser">
              {params.row.lastname} {params.row.firstname}
            </div>
          );
        },
      },
      {
        field: "username",
        headerName: "Nom d'utilisateur",
        width: 200,
      },
      { field: "email", headerName: "Email", width: 200 },
      {
        field: "role",
        headerName: "Role",
        width: 150,
      },
      {
        field: "active",
        headerName: "Compte",
        width: 150,
        renderCell: (params) => {
          return (
            <>
              {params.row.active && <Text color="success">actif</Text>}
              {!params.row.active && <Text color="error">inactif</Text>}
            </>
          );
        },
      },
      {
        field: "action",
        headerName: "Action",
        width: 250,
        renderCell: (params) => {
          return (
            <>
              <Button
                auto
                color="secondary"
                rounded
                flat
                onClick={() => {
                  if (
                    currentUser?.role !== "superadmin" &&
                    currentUser?._id !== params.row._id
                  ) {
                    toast.warning(
                      "Vous n'avez pas assez de permissions pour modifier un super administrateur",
                      {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        transition: Flip,
                      }
                    );
                  } else {
                    history.push(`/manage/users/admin/${params.row._id}`);
                  }
                }}
              >
                Modifier
              </Button>

              {params.row.active && currentUser._id !== params.row._id && (
                <Button
                  light
                  color="error"
                  auto
                  onClick={() => {
                    if (currentUser.role !== "superadmin") {
                      toast.warning(
                        "Vous n'avez pas assez de permissions pour désactiver un super administrateur",
                        {
                          position: "bottom-right",
                          autoClose: 3000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          transition: Flip,
                        }
                      );
                    } else {
                      setUser({
                        _id: params.row._id,
                        lastname: params.row.lastname,
                        firstname: params.row.firstname,
                        active: params.row.active,
                      });
                      handler();
                    }
                  }}
                >
                  Désactiver
                </Button>
              )}
              {!params.row.active && currentUser._id !== params.row._id && (
                <Button
                  light
                  color="success"
                  auto
                  onClick={() => {
                    if (currentUser.role !== "superadmin") {
                      toast.warning(
                        "Vous n'avez pas assez de permissions pour activer un super administrateur",
                        {
                          position: "bottom-right",
                          autoClose: 3000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          transition: Flip,
                        }
                      );
                    } else {
                      setUser({
                        _id: params.row._id,
                        lastname: params.row.lastname,
                        firstname: params.row.firstname,
                        active: params.row.active,
                      });
                      handler();
                    }
                  }}
                >
                  Activer
                </Button>
              )}
            </>
          );
        },
      },
    ]
    : [
      {
        field: "name",
        headerName: "Client",
        width: 350,
        renderCell: (params) => {
          return (
            <div className="userListUser">
              {params.row.lastname} {params.row.firstname}
            </div>
          );
        },
      },
      { field: "email", headerName: "Email", width: 250 },
      {
        field: "active",
        headerName: "Status",
        width: 150,
        renderCell: (params) => {
          return (
            <>
              {params.row.active && <Text color="success">actif</Text>}
              {!params.row.active && <Text color="error">inactif</Text>}
            </>
          );
        },
      },
      {
        field: "volume",
        headerName: "Volume de commande",
        width: 250,
        renderCell: () => {
          return <>-</>;
        },
      },
      {
        field: "action",
        headerName: "Action",
        width: 250,
        renderCell: (params) => {
          return (
            <>
              <Link to={"/manage/users/user/" + params.row._id}>
                <Button auto color="secondary" rounded flat>
                  Modifier
                </Button>
              </Link>

              {params.row.active && (
                <Button
                  light
                  color="error"
                  auto
                  onClick={() => {
                    setUser({
                      _id: params.row._id,
                      lastname: params.row.lastname,
                      firstname: params.row.firstname,
                      role: params.row.role,
                      active: params.row.active,
                    });
                    handler();
                  }}
                >
                  Désactiver
                </Button>
              )}
              {!params.row.active && (
                <Button
                  light
                  color="success"
                  auto
                  onClick={() => {
                    setUser({
                      _id: params.row._id,
                      lastname: params.row.lastname,
                      firstname: params.row.firstname,
                      role: params.row.role,
                      active: params.row.active,
                    });
                    handler();
                  }}
                >
                  Activer
                </Button>
              )}
            </>
          );
        },
      },
    ];

  return (
    <Container className="userList">
      <Spacer y={1} />
      <Row align="center" justify="space-between">
        {!manager && <Text h1>Clients</Text>}
        {manager && <Text h1>Personnel</Text>}
        {manager && (
          <Link to="/manage/users/newuser">
            <Button auto>Ajouter un utilisateur</Button>
          </Link>
        )}
      </Row>
      <Spacer y={1} />
      {isLoading && (
        <Progress
          indeterminated
          color="secondary"
          status="secondary"
          size="mini"
        />
      )}
      {!isLoading && (
        <DataGrid
          localeText={frFR.props.MuiDataGrid.localeText}
          rows={users}
          disableSelectionOnClick
          columns={columns}
          getRowId={(row) => row._id}
          pageSize={8}
          columnBuffer={8}
          checkboxSelection
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            setSelectedRowsData(selectedIDs);
          }}
        />
      )}
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            {user.active && (
              <span>
                Désactivation de compte{" "}
                {user.role === "user" && <span> client</span>}
              </span>
            )}
            {!user.active && (
              <span>
                Activation de compte
                {user.role === "user" && <span> client</span>}
              </span>
            )}
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Text>
            {user.active && <span>Désactiver</span>}
            {!user.active && <span>Activer</span>} le compte{" "}
            {user.role === "user" && <span> client</span>} de{" "}
            <span style={{ fontWeight: "bold" }}>
              {user.lastname} {user.firstname}
            </span>{" "}
            ?{" "}
          </Text>
          {!user.active && user.role === "user" && (
            <Text color="success" h6>
              Le client pourra se connecter à nouveau au site web.
            </Text>
          )}
          {user.active && user.role === "user" && (
            <Text color="warning" h6>
              Le client ne pourra plus se connecter au site web.
            </Text>
          )}
          {user.active && user.role !== "user" && (
            <Text color="warning" h6>
              Cet utilisateur n'aura plus accès à l'administration.
            </Text>
          )}
          {!user.active && user.role !== "user" && (
            <Text color="success" h6>
              Cet utilisateur aura désormais accès à l'administration
            </Text>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat onClick={closeHandler}>
            Annuler
          </Button>
          <Button
            auto
            {...(loading && { loading })}
            onClick={handle}
            color="error"
            loaderType="spinner"
          >
            Continuer
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </Container>
  );
}
