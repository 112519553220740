import React, { useState } from "react";
import "./fileUpload.css";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { BASE_URL, TOKEN } from "../../requestMethods";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);
let pond = FilePond;

export default function FileUpload({
  setFilePond,
  maxFiles,
  files,
  setFiles,
  uploadedFiles,
  setUploadedFiles,
}) {
  return (
    <FilePond
      ref={(ref) => (pond = ref)}
      files={files}
      allowMultiple={true}
      allowReorder={true}
      maxFiles={maxFiles}
      maxParallelUploads={1}
      required={true}
      maxFileSize="2MB"
      acceptedFileTypes={"image/webp"}
      fileValidateTypeDetectType={(source, type) =>
        new Promise((resolve, reject) => {
          resolve(type);
        })
      }
      fileValidateTypeLabelExpectedTypes={"{lastType} attendu"}
      labelFileTypeNotAllowed= {"Image de type non valide"}
      labelMaxFileSizeExceeded= {"L'image est trop grand"}
      labelIdle={`Faites glisser et déposez vos fichiers ou <span class="filepond--label-action"> Selectionnez </span>.`}
      labelInvalidField={`Le champ contient des fichiers invalides`}
      labelFileWaitingForSize={`En attente de la taille`}
      labelFileSizeNotAvailable={`Taille non disponible`}
      labelFileLoading={`Envoi`}
      labelFileLoadError={`Erreur pendant l'envoi`}
      labelFileProcessing={`Chargement...`}
      labelFileProcessingComplete={`Envoi terminé`}
      labelFileProcessingAborted={`Envoi annulé`}
      labelFileProcessingError={`Erreur pendant l'envoi`}
      labelFileProcessingRevertError={`Erreur lors de la réversion`}
      labelFileRemoveError={`Erreur lors de la suppression`}
      labelTapToCancel={`tapez pour annuler`}
      labelTapToRetry={`tapez pour réessayer`}
      labelTapToUndo={`tapez pour annuler`}
      labelButtonRemoveItem={`Retirer`}
      labelButtonAbortItemLoad={`Abandonner`}
      labelButtonRetryItemLoad={`Réessayer`}
      labelButtonAbortItemProcessing={`Annuler`}
      labelButtonUndoItemProcessing={`Revenir`}
      labelButtonRetryItemProcessing={`Réessayer`}
      labelButtonProcessItem={`Charger`}
      server={{
        url: BASE_URL,
        process: {
          url: "/upload",
          method: "POST",
          withCredentials: false,
          headers: {
            token: `Bearer ${TOKEN}`,
          },
          timeout: 7000,
          onload: (response) => {
            setUploadedFiles((prev) =>
              uploadedFiles.includes(response)
                ? uploadedFiles
                : [...prev, JSON.parse(response)]
            );
            setFilePond(pond);
          },
          onerror: null,
          ondata: null,
        },
        revert: {
          url: "/upload/revert",
          method: "DELETE",
          withCredentials: false,
          headers: {
            token: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
          onload: (response) => {
            setFilePond(pond);
            return JSON.parse(response).path; // added
          },
          onerror: null,
          ondata: null,
        },
        load: (source, load, error, progress, abort, headers) => {
          var myRequest = new Request(source);
          fetch(myRequest, {
            mode: "no-cors",
          }).then(function (response) {
            response.blob().then(function (myBlob) {
              load(myBlob);
            });
          });
        },
      }}
      name="files"
      onupdatefiles={(fileItems) => {
        setFilePond(pond);
        // Set currently active file objects to this.state
        setFiles(fileItems.map((fileItem) => fileItem.file));
      }}
    />
  );
}