import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import "./newCategory.css";
import {
  addCategory,
  getCategory,
  updateCategory,
} from "../../redux/apiRequests";
import { useDispatch, useSelector } from "react-redux";
import { Container, Input, Text, Row, Spacer, Button } from "@nextui-org/react";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { resetCategory, resetCurrentCategory } from "../../redux/categoryRedux";

export default function NewCategory() {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const history = useHistory();
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category.category);
  const [currentLanguage, setCurrentLanguage] = useState("fr");
  const [inputs, setInputs] = useState({});
  const [helper, setHelper] = useState({
    name: {
      text: "",
      color: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const isDuplicate = useSelector((state) => state.category.isDuplicate);
  const user = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    dispatch(resetCategory());
    dispatch(resetCurrentCategory());
  }, []);

  useEffect(() => {
    getCategory(id, dispatch);
  }, [id]);

  useEffect(() => {
    if (isDuplicate) {
      toast.error("La catégorie que vous essayer d'ajouter existe déjà !", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip,
      });
    }
  }, [isDuplicate]);

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleLanguageChange = (e) => {
    setCurrentLanguage(e.target.value);
    resetHelpers();
  };

  const resetHelpers = (e) => {
    setHelper((prev) => {
      return {
        ...prev,
        name: {
          text: "",
          color: "",
        },
      };
    });

    setHelper((prev) => {
      return {
        ...prev,
        name: {
          text: "",
          color: "",
        },
      };
    });
  };

  const handleClick = (e) => {
    e.preventDefault();

    inputs.name &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "",
            color: "",
          },
        };
      });

    inputs.name_en &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "",
            color: "",
          },
        };
      });

    !category &&
      !inputs.name &&
      currentLanguage === "en" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "La version en français est manquante. Veuillez changer de langue en cliquant sur la sélection en haut à droite",
            color: "error",
          },
        };
      });

    !category &&
      !inputs.name_en &&
      currentLanguage === "fr" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "La version anglaise est manquante. Veuillez changer de langue en cliquant sur la sélection en haut à droite",
            color: "error",
          },
        };
      });

    !category &&
      !inputs.name_en &&
      currentLanguage === "en" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "Le nom (anglais) de la nouvelle catégorie ne peut être vide",
            color: "error",
          },
        };
      });

    !category &&
      !inputs.name &&
      currentLanguage === "fr" &&
      setHelper((prev) => {
        return {
          ...prev,
          name: {
            text: "Le nom de la nouvelle catégorie ne peut être vide",
            color: "error",
          },
        };
      });

    if (!category && inputs.name && inputs.name_en) {
      dispatch(resetCategory());
      setLoading(true);
      const slug = inputs.name_en.toLowerCase().replace(/ /g, "-");
      addCategory(
        {
          slug: slug,
          name: inputs.name,
          en: { name: inputs.name_en },
          updatedBy: user._id,
        },
        dispatch
      )
        .then(() => {
          setLoading(false);
          if (!isDuplicate) {
            toast.success("La catégorie a été bien ajouté", {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              transition: Flip,
            });
            setTimeout(() => {
              history.push("/categories");
            }, 2000);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (category && (inputs.name || inputs.name_en)) {
      setLoading(true);
      const slug = inputs.name_en
        ? inputs.name_en.toLowerCase().replace(/ /g, "-")
        : category.slug;
      updateCategory(
        category._id,
        {
          slug: slug,
          name: inputs.name ? inputs.name : category.name,
          en: { name: inputs.name_en ? inputs.name_en : category.en.name },
          updatedBy: user._id,
        },
        dispatch
      )
        .then(() => {
          setLoading(false);
          if (!isDuplicate) {
            toast.success("La catégorie a été bien mise à jour", {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              transition: Flip,
            });
            dispatch(resetCurrentCategory());
            setTimeout(() => {
              history.push("/categories");
            }, 2000);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Container className="newProduct">
      <Spacer y={1} />
      <Row align="center" justify="space-between">
        <Text>
          <Text h1>
            {id ? "Modifier la " : "Nouvelle "}
            catégorie
          </Text>
          <Text h3>{currentLanguage === "en" && "(version anglaise)"}</Text>
        </Text>
        <div className="select-container-language">
          <div className="select-wrapper">
            <select
              className="next-ui-select"
              name="language"
              onChange={handleLanguageChange}
            >
              <option value="fr">Français</option>
              <option value="en">Anglais</option>
            </select>
          </div>
        </div>
      </Row>
      <Row>
        <form className="addCategoryForm">
          {currentLanguage === "fr" && (
            <Input
              label="Nom"
              placeholder="Nouveaux produits"
              size="large"
              width="100%"
              name="name"
              value={inputs.name}
              shadow={false}
              bordered
              initialValue={!id || !category ? null : category?.name}
              color={helper.name.color}
              helperColor={helper.name.color}
              helperText={helper.name.text}
              onChange={handleChange}
            />
          )}
          {currentLanguage === "en" && (
            <Input
              label="Nom"
              placeholder="New products"
              size="large"
              width="100%"
              name="name_en"
              value={inputs.name_en}
              shadow={false}
              bordered
              initialValue={!id || !category ? null : category?.en?.name}
              color={helper.name.color}
              helperColor={helper.name.color}
              helperText={helper.name.text}
              onChange={handleChange}
            />
          )}
          <Spacer y={1.5} />
          <Button
            loaderType="spinner"
            color="success"
            {...(loading && { loading })}
            onClick={handleClick}
          >
            {id ? "Modifier" : "Ajouter"}
          </Button>
          <Spacer y={1.5} />
        </form>
      </Row>
      <ToastContainer />
    </Container>
  );
}
