import "./widgetLg.css";
import { userRequest } from "../../requestMethods";
import { useEffect, useState } from "react";
import * as timeago from 'timeago.js';
import { Button } from "@nextui-org/react";
import { useHistory } from "react-router";

export default function WidgetLg() {
  const history = useHistory();

  const Button = ({ type }) => {
    return <button className={"widgetLgButton " + type}>{type}</button>;
  };
  const [orders, setOrders] = useState([]);

  // the local dict example is below.
  const localeFunc = (number, index, totalSec) => {
    // number: the timeago / timein number;
    // index: the index of array below;
    // totalSec: total seconds between date to be formatted and today's date;
    return [
      ['à l\'instant', 'right now'],
      ['il y a %s secondes', 'in %s seconds'],
      ['il y a 1 minute', ' in 1 minute'],
      ['il y a %s minutes', ' in % s minutes'],
      ['il y a 1 heure', ' in 1 hour'],
      ['il y a %s heures', ' in % s hours'],
      ['il y a 1 jour', ' in 1 day'],
      ['il y a %s jours', ' in % s days'],
      ['il y a 1 semaine', ' in 1 week'],
      ['il y a %s semaines', ' in % s weeks'],
      ['il y a 1 mois', ' in 1 month'],
      ['il y a %s mois', ' in % s months'],
      ['il y a 1 an', ' in 1 year'],
      ['il y a %s ans', ' in % s years']
    ][index];
  };

  // register your locale with timeago
  timeago.register('my-locale', localeFunc);

  useEffect(() => {
    const getOrders = async () => {
      try {
        const response = await userRequest.get("orders?delivered=false");
        setOrders(response.data);
      } catch { }
    };
    getOrders();
  }, []);

  const format = (str) => {
    return str.replace(/^#/, '');
  };

  return (
    <div className="widgetLg">
      <h3 className="widgetLgTitle">Dernières commandes</h3>
      <table className="widgetLgTable">
        <tr className="widgetLgTr">
          <th className="widgetLgTh">N°</th>
          <th className="widgetLgTh">Date</th>
          <th className="widgetLgTh">Client</th>
          <th className="widgetLgTh">Action</th>
        </tr>
        {orders.map((order) => (
          <tr className="widgetLgTr" key={order._id}>
            <td className="widgetLgNoOrders">
              {order.orderNumber}
            </td>
            <td className="widgetLgDate">
              {timeago.format(order.createdAt, 'my-locale')}
            </td>
            <td className="widgetLgUser">
              {order.userId?.firstname} {order.userId?.lastname}
            </td>
            <td className="widgetLgButton" onClick={() => {
              history.push(`/orders/order/${format(order.orderNumber)}`);
            }}>
              <Button
                type="Consulter"
              >
              </Button>
            </td>
          </tr>
        ))}
        {orders.length === 0 && (
          <tr className="widgetLgTr">
            <td colSpan="4" className="widgetLgNoOrders">
              Aucune
            </td>
          </tr>
        )}
      </table>
    </div >
  );
}
