import "./orderList.css";
import { frFR, DataGrid } from "@material-ui/data-grid";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrders,
  updateOrder,
} from "../../redux/apiRequests";
import {
  Button,
  Container,
  Row,
  Spacer,
  Text,
  Modal,
  Progress,
} from "@nextui-org/react";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetRange } from "../../redux/rangeRedux";
import { resetOrder } from "../../redux/orderRedux";
import { resetProduct } from "../../redux/productRedux";
import { resetCategory } from "../../redux/categoryRedux";
const dayjs = require('dayjs');

export default function OrderList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const orders = useSelector((state) => state.order.orders);
  const [visible, setVisible] = useState(false);
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const isLoading = useSelector((state) => state.order.isFetching);
  const handler = () => setVisible(true);
  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const closeHandler = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch(resetRange());
    dispatch(resetCategory());
    dispatch(resetProduct());
  }, []);

  useEffect(() => {
    getOrders(dispatch);
  }, [dispatch]);

  const handleUpdate = () => {
    setLoading(true);
    order.action === "cancel" && updateOrder(
      order._id,
      { status: "cancelled" },
      dispatch
    ).then(() => {
      toast.success("La commmande a été annulée !", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip,
      });
      closeHandler();
      setLoading(false);
    });

    order.action === "process" && updateOrder(
      order._id,
      { status: "processing" },
      dispatch
    ).then(() => {
      toast.info("La commande est en cours de traitemant", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip,
      });
      closeHandler();
      setLoading(false);
    });

    order.action === "deliver" && updateOrder(
      order._id,
      { status: "in_transit" },
      dispatch
    ).then(() => {
      toast.info("La commande est en cours de livraison", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip,
      });
      closeHandler();
      setLoading(false);
    });

    order.action === "complete" && updateOrder(
      order._id,
      { status: "delivered" },
      dispatch
    ).then(() => {
      toast.success("La commande est livrée", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip,
      });
      closeHandler();
      setLoading(false);
    });
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      width: 100,
      hide: true,
    },
    {
      field: "orderNumber",
      headerName: "N°",
      width: 100,
    },
    {
      field: "updatedAt",
      headerName: "Date",
      width: 110,
      renderCell: (params) => {
        return (
          <Text>{dayjs(params.row.updatedAt).format("DD/MM/YYYY")}</Text>
        );
      },
    },
    {
      field: "userId",
      headerName: "Client",
      width: 250,
      valueFormatter: (params) => {
        return (
          params.row.userId.lastname + " " + params.row.userId.firstname
        );
      },
      renderCell: (params) => {
        return (
          <Text>{params.row.userId.lastname}{" "}{params.row.userId.firstname}</Text>
        );
      },
    },
    {
      field: "products",
      headerName: "Products",
      width: 280,
      valueFormatter: (params) => {
        return (
          params.row.products.map((product, i) => {
            return (
              i !== params.row.products.length - 1 ? product.productId.name + ", " : product.productId.name
            );
          })
        );
      },
      renderCell: (params) => {
        return (
          <>
            {params.row.products
              .slice(0, 1)
              .map((product) => {
                return (
                  <div className="saleItem">
                    <Text>{product.productId.name}</Text>
                  </div>
                );
              })}
            {params.row.products.length > 1 && (
              <div className="saleItem">
                <Text>, ...</Text>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Nbre produits",
      width: 180,
      valueFormatter: (params) => {
        return params.row.products.length;
      },
      renderCell: (params) => {
        return (
          <>
            {params.row.products.length}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      valueFormatter: (params) => {
        return (
          "Livré"
        )
      },
      renderCell: (params) => {
        return (
          <>
            {params.row.status === "cancelled" && (
              <Text color="error">Annulé</Text>
            )}
            {params.row.status === "pending" && (
              <Text color="warning">En attente</Text>
            )}
            {params.row.status === "processing" && (
              <Text color="primary">En cours</Text>
            )}
            {params.row.status === "in_transit" && (
              <Text color="primary">En livraison</Text>
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      disableExport: true,
      renderCell: (params) => {
        return (
          <>
            {(params.row.status === "pending" || params.row.status === "processing" || params.row.status === "in_transit") &&
              (
                <Button
                  color="error"
                  auto
                  flat
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setOrder({
                      _id: params.row._id,
                      orderNumber: params.row.orderNumber,
                      action: "cancel",
                    });
                    handler();
                  }}
                >
                  Annuler
                </Button>
              )
            }
            {
              params.row.status === "pending" && (
                <Button
                  color="primary"
                  auto
                  flat
                  onClick={() => {
                    setOrder({
                      _id: params.row._id,
                      orderNumber: params.row.orderNumber,
                      action: "process",
                    });
                    handler();
                  }}
                >
                  Traiter
                </Button>
              )
            }
            {
              params.row.status === "processing" && (
                <Button
                  color="primary"
                  auto
                  flat
                  onClick={() => {
                    setOrder({
                      _id: params.row._id,
                      orderNumber: params.row.orderNumber,
                      action: "deliver",
                    });
                    handler();
                  }}
                >
                  Livrer
                </Button>
              )
            }
            {
              params.row.status === "in_transit" && (
                <Button
                  color="success"
                  auto
                  flat
                  onClick={() => {
                    setOrder({
                      _id: params.row._id,
                      orderNumber: params.row.orderNumber,
                      action: "complete",
                    });
                    handler();
                  }}
                >
                  Confirmer
                </Button>
              )
            }
          </>
        );
      }
    }
  ];

  const format = (str) => {
    return str.replace(/^#/, '');
  };

  return (
    <Container className="orderList">
      <Spacer y={1} />
      <Row align="center" justify="space-between">
        <Text h1>Commandes</Text>
      </Row>
      <Spacer y={1} />
      {isLoading && (
        <Progress
          indeterminated
          color="secondary"
          status="secondary"
          size="mini"
        />
      )}
      <DataGrid
        localeText={frFR.props.MuiDataGrid.localeText}
        rows={orders}
        disableSelectionOnClick
        columns={columns}
        getRowId={(row) => row._id}
        pageSize={8}
        columnBuffer={8}
        checkboxSelection
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          setSelectedRowsData(selectedIDs);
        }}
        onCellClick={(params) => {
          if (params.field !== "action") {
            history.push(`/orders/order/${format(params.row.orderNumber)}`);
          }
        }}
      />

      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>

          <Text id="modal-title" size={18}>
            {order.action === "cancel" && "Annuler une commande"}
            {order.action === "process" && "Traiter une commande"}
            {order.action === "deliver" && "Livrer une commande"}
            {order.action === "complete" && "Confirmer une commande"}
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Text>
            {order.action === "cancel" && <span>Voulez-vous vraiment annuler la commande`{" "}</span>}
            {order.action === "process" && <span>
              En confirmant cette action, vous traitez la commande cliente numéro {" "}
            </span>}
            {order.action === "deliver" && <span>
              En confirmant cette action, vous livrez la commande numéro {" "}
            </span>}
            {order.action === "complete" && <span>
              La commande numéro {" "}
            </span>}
            <span style={{ fontWeight: "bold" }}>{order.orderNumber}</span>
            {order.action === "process" && <span> {" "}
              et sera disponible pour la livraison dès que vous aurez valider le paiement. (Echanges whatsapp etc..)
            </span>}
            {order.action === "deliver" && <span> {" "}
              au client.
            </span>}
            {order.action === "complete" && <span> {" "}
              sera marquée comme livrée.
            </span>}
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat onClick={closeHandler}>
            Retour
          </Button>
          <Button
            auto
            {...(loading && { loading })}
            onClick={handleUpdate}
            color="error"
            loaderType="spinner"
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </Container>
  );
}
