import { createSlice } from "@reduxjs/toolkit";

export const AssetSlice = createSlice({
  name: "Asset",
  initialState: {
    Assets: [],
    isUploading: false,
    error: false,
  },
  reducers: {
    resetAsset: (state) => {
      state.isUploading = false;
      state.error = false;
    },
    //GET ALL
    uploadAssetStart: (state) => {
      state.isUploading = true;
      state.error = false;
    },
    uploadAssetSuccess: (state, action) => {
      state.isUploading = false;
      state.Assets = action.payload;
    },
    uploadAssetFailure: (state) => {
      state.isUploading = false;
      state.error = true;
    },
    removeAssetStart: (state) => {
      state.isUploading = true;
      state.error = false;
    },
    removeAssetSuccess: (state, action) => {
      state.isUploading = false;
      // remove the asset from the list
      state.Assets = state.Assets.filter((asset) => asset !== action.payload);
    },
    removeAssetFailure: (state) => {
      state.isUploading = false;
      state.error = true;
    }
  },
});

export const {
  resetAsset,
  uploadAssetStart,
  uploadAssetSuccess,
  uploadAssetFailure,
  removeAssetStart,
  removeAssetSuccess,
  removeAssetFailure,
} = AssetSlice.actions;

export default AssetSlice.reducer;
