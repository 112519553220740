import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false,
    role: null,
    status: null,
    isDuplicate: false,
  },
  reducers: {
    resetError: (state) => {
      state.error = false;
    },
    resetUser: (state) => {
      state.currentUser = null;
      state.isFetching = false;
      state.error = false;
      state.role = null;
      state.status = null;
    },
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.role = action.payload.role;
      if (action.payload.role === "user") {
        state.error = "401";
      } else if (!action.payload.active && action.payload.role !== "user") {
        state.error = "45";
      }
    },
    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.role = null;
    },
    logout: (state) => {
      state.currentUser = null;
    },
    resetUserSession: (state) => {
      state.status = null;
    },
    getUserStart: (state) => {
      state.isFetching = true;
    },
    getUserSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = {
        ...state.currentUser,
        ...action.payload,
      };
      state.role = action.payload.user;
    },
    getUserFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.role = null;
    },
    addUserStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addUserSuccess: (state, action) => {
      state.isFetching = false;
      state.users.push(action.payload);
    },
    addUserFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    addUserDuplicateFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.isDuplicate = true;
    },
    getUserExpiredFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.status = "403";
      resetUser();
      resetUserSession();
    },
    updateUserStart: (state) => {
      state.isFetching = true;
    },
    updateUserSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = {
        ...state.currentUser,
        ...action.payload,
      };
      state.role = action.payload.user;
    },
    updateUserFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.role = null;
    },
    deleteUserStart: (state) => {
      state.isFetching = true;
    },
    deleteUserSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.role = action.payload.user;
    },
    deleteUserFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.role = null;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  resetError,
  logout,
  getUserStart,
  getUserSuccess,
  getUserFailure,
  getUserExpiredFailure,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  resetUser,
  resetUserSession,
  addUserStart,
  addUserFailure,
  addUserSuccess,
  addUserDuplicateFailure,
} = userSlice.actions;
export default userSlice.reducer;
