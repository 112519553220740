import "./sidebar.css";
import {
  MdBarChart,
  MdOutlineSpaceDashboard,
  MdOutlineStorefront,
  MdOutlineAttachMoney,
} from "react-icons/md";
import { IoAnalytics, IoTrendingUp, IoTicketOutline } from "react-icons/io5";
import { RiUserLine } from "react-icons/ri";
import { CgWorkAlt } from "react-icons/cg";
import { BsCollection } from "react-icons/bs";
import { VscGift } from "react-icons/vsc";
import { RiAdvertisementLine } from "react-icons/ri";
import { BiCategory } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar() {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMIoTrendingUpenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <Link to="/" className="link">
              <li className={path === "/" ? "sidebarListItem  active" : "sidebarListItem"}>
                <MdOutlineSpaceDashboard className="sidebarIcon" />
                Acceuil
              </li>
            </Link>
            <Link to="/notavailable" className="link link-disabled">
              <li className={path === "/notavailable" ? "sidebarListItem  active" : "sidebarListItem"}>
                <IoAnalytics className="sidebarIcon" />
                Analytiques
              </li>
            </Link>
            <Link to="/orders" className="link">
            <li className={path === "/orders" ? "sidebarListItem  active" : "sidebarListItem"}>
                <MdOutlineAttachMoney className="sidebarIcon" />
                Commandes
              </li>
            </Link>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Stocks</h3>
          <ul className="sidebarList">
            <Link to="/products" className="link">
              <li className={path === "/products" ? "sidebarListItem  active" : "sidebarListItem"}>
                <MdOutlineStorefront className="sidebarIcon" />
                Produits
              </li>
            </Link>
            <Link to="/ranges" className="link">
              <li className={path === "/ranges" ? "sidebarListItem  active" : "sidebarListItem"}>
                <BsCollection className="sidebarIcon" />
                Gammes
              </li>
            </Link>
            <Link to="/categories" className="link">
              <li className={path === "/categories" ? "sidebarListItem  active" : "sidebarListItem"}>
                <BiCategory className="sidebarIcon" />
                Categories
              </li>
            </Link>
          </ul>
        </div>
        <div className="sidebarMenu">
          <ul className="sidebarList">
            <Link to="/users" className="link">
              <li className={path === "/users" ? "sidebarListItem  active" : "sidebarListItem"}>
                <RiUserLine className="sidebarIcon" />
                Clients
              </li>
            </Link>
            <Link to="/sales" className="link">
              <li className="sidebarListItem">
                <IoTrendingUp className="sidebarIcon" />
                Ventes
              </li>
            </Link>
            <Link to="/notavailable" className="link link-disabled">
              <li className={path === "/notavailable" ? "sidebarListItem  active" : "sidebarListItem"}>
                <IoTicketOutline className="sidebarIcon" />
                Réductions
              </li>
            </Link>
            <Link to="/notavailable" className="link link-disabled">
              <li className={path === "/notavailable" ? "sidebarListItem  active" : "sidebarListItem"}>
                <VscGift className="sidebarIcon" />
                Coupons
              </li>
            </Link>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Personnel</h3>
          <ul className="sidebarList">
            <Link to="/manage/users" className="link">
              <li className={path === "/manage/users" ? "sidebarListItem  active" : "sidebarListItem"}>
                <CgWorkAlt className="sidebarIcon" />
                Gérer
              </li>
            </Link>
            <Link to="/notavailable" className="link link-disabled">
              <li className={path === "/notavailable" ? "sidebarListItem  active" : "sidebarListItem"}>
                <MdBarChart className="sidebarIcon" />
                Rapports
              </li>
            </Link>
            <Link to="/notavailable" className="link link-disabled">
              <li className={path === "/notavailable" ? "sidebarListItem  active" : "sidebarListItem"}>
                <RiAdvertisementLine className="sidebarIcon" />
                Marketing
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
}
