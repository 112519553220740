import { useState } from "react";
import "./settings.css";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Input,
  Text,
  Row,
  Spacer,
  Button,
  Collapse,
} from "@nextui-org/react";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import {
  addUser,
  getCurrentUser,
  getStore,
  updateStore,
  updateUser,
} from "../../redux/apiRequests";
import { WithContext as ReactTags } from "react-tag-input";
import { useHistory, useLocation } from "react-router";
import { getUsersStart } from "../../redux/usersRedux";
import { getUserFailure, getUserSuccess } from "../../redux/userRedux";

export default function Settings() {
  const location = useLocation();
  const userType = location.pathname.split("/")[3];
  const userId = location.pathname.split("/")[4];
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [currentUser, setCurrentUser] = useState({});
  const isDuplicate = useSelector((state) => state.range.isDuplicate);
  const [inputs, setInputs] = useState({});
  const [helper, setHelper] = useState({
    email: {
      text: "",
      color: "",
    },
    firstname: {
      text: "",
      color: "",
    },
    lastname: {
      text: "",
      color: "",
    },
    username: {
      text: "",
      color: "",
    },
    newPassword: {
      text: "",
      color: "",
    },
    confirm: {
      text: "",
      color: "",
    },
    storeEmail: {
      text: "",
      color: "",
    },
    businessPhone: {
      text: "Il s'agit du numéro de téléphone à joindre quand la commande est passée",
      color: "",
    },
    phones: {
      text: "Il s'agit de la liste des numéros de téléphones affiché directement sur la page d'acceuil du site web",
      color: "",
    },
    others: {
      text: "Cette liste contient tous les numéros à contacter réprésentatif de chaque pays",
      color: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const KeyCodes = {
    comma: 188,
    enter: [10, 13],
  };
  const delimiters = [...KeyCodes.enter, KeyCodes.comma];
  const [phones, setPhones] = useState([{ id: "Thailand", text: "Thailand" }]);
  const [otherPhones, setOtherPhones] = useState([]);

  const stores = useSelector((state) => state.store.stores);

  useEffect(() => {
    getStore(dispatch).then(() => {
      setPhones(() => {
        return stores[0]?.phones?.map((phone) => {
          return { id: phone, text: phone };
        });
      });
      setOtherPhones(() => {
        return stores[0]?.others?.map((phone) => {
          return { id: phone, text: phone };
        });
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (isDuplicate) {
      toast.error("L'utilisateur existe déjà !", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip,
      });
    }
  }, [isDuplicate]);

  useEffect(async () => {
    if (userType && userId && userType !== "newuser") {
      dispatch(getUsersStart());
      try {
        await getCurrentUser(userId).then((res) => {
          setCurrentUser(res.data);
          dispatch(getUserSuccess());
        });
      } catch (error) {
        dispatch(getUserFailure());
      }
    }
  }, [userId]);

  const handleDeletePhones = (i) => {
    setPhones(phones?.filter((phone, index) => index !== i));
  };

  const handleDeleteOthersPhones = (i) => {
    setOtherPhones(otherPhones?.filter((phone, index) => index !== i));
  };

  const handleAdditionPhones = (phone) => {
    setPhones((prev) => [...prev, phone]);
  };

  const handleAdditionOthersPhones = (phone) => {
    setOtherPhones((prev) => [...prev, phone]);
  };

  const handleDragPhones = (phone, currPos, newPos) => {
    const phonesList = [...phones];
    const newPhones = phonesList?.slice();

    newPhones.splice(currPos, 1);
    newPhones.splice(newPos, 0, phone);

    // re-render
    setPhones(newPhones);
  };

  const handleDragOthersPhones = (phone, currPos, newPos) => {
    const otherPhonesList = [...otherPhones];
    const newPhones = otherPhonesList?.slice();

    newPhones.splice(currPos, 1);
    newPhones.splice(newPos, 0, phone);

    // re-render
    setOtherPhones(newPhones);
  };

  const handlePhonesChange = (e) => {
    setHelper((prev) => {
      return {
        ...prev,
        phones: {
          text: "",
          color: "",
        },
      };
    });
  };

  const handleOthersPhonesChange = (e) => {
    setHelper((prev) => {
      return {
        ...prev,
        others: {
          text: "",
          color: "",
        },
      };
    });
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    resetHelpers(e);
  };

  const resetHelpers = (e) => {
    e?.target?.name === "email" &&
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: "",
            color: "",
          },
        };
      });

    e?.target?.name === "firstname" &&
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: "",
            color: "",
          },
        };
      });

    e?.target?.name === "lastname" &&
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: "",
            color: "",
          },
        };
      });

    e?.target?.name === "username" &&
      setHelper((prev) => {
        return {
          ...prev,
          username: {
            text: "",
            color: "",
          },
        };
      });

    e?.target?.name === "newPassword" &&
      setHelper((prev) => {
        return {
          ...prev,
          newPassword: {
            text: "",
            color: "",
          },
        };
      });

    e?.target?.name === "confirm" &&
      setHelper((prev) => {
        return {
          ...prev,
          confirm: {
            text: "",
            color: "",
          },
        };
      });

    e?.target?.name === "confirm" &&
      setHelper((prev) => {
        return {
          ...prev,
          confirm: {
            text: "",
            color: "",
          },
        };
      });

    e?.target?.name === "storeEmail" &&
      setHelper((prev) => {
        return {
          ...prev,
          storeEmail: {
            text: "",
            color: "",
          },
        };
      });

    e?.target?.name === "businessPhone" &&
      setHelper((prev) => {
        return {
          ...prev,
          businessPhone: {
            text: "",
            color: "",
          },
        };
      });

    e?.target?.name === "phones" &&
      setHelper((prev) => {
        return {
          ...prev,
          phones: {
            text: "",
            color: "",
          },
        };
      });

    e?.target?.name === "otherPhones" &&
      setHelper((prev) => {
        return {
          ...prev,
          others: {
            text: "",
            color: "",
          },
        };
      });
  };

  const handleClick = async (e) => {
    e.preventDefault();

    ((!inputs.email &&
      inputs.email !== undefined &&
      inputs.email !== user.currentUser.email) ||
      (!inputs.email && userType && userType === "newuser")) &&
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: "L'email est obligatoire",
            color: "error",
          },
        };
      });

    ((!inputs.firstname &&
      inputs.firstname !== undefined &&
      inputs.firstname !== user.currentUser.firstname) ||
      (!inputs.firstname && userType && userType === "newuser")) &&
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: "Le prénom ne peut être vide",
            color: "error",
          },
        };
      });

    ((!inputs.lastname &&
      inputs.lastname !== undefined &&
      inputs.lastname !== user.currentUser.lastname) ||
      (!inputs.lastname && userType && userType === "newuser")) &&
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: "Le nom ne peut être vide",
            color: "error",
          },
        };
      });

    ((!inputs.username &&
      inputs.username !== undefined &&
      inputs.username !== user.currentUser.username) ||
      (!inputs.username && userType && userType === "newuser")) &&
      setHelper((prev) => {
        return {
          ...prev,
          username: {
            text: "Le nom d'utilisateur ne peut être vide",
            color: "error",
          },
        };
      });

    ((!inputs.newPassword && inputs.confirm) ||
      (!inputs.newPassword && userType && userType === "newuser")) &&
      setHelper((prev) => {
        return {
          ...prev,
          newPassword: {
            text:
              userType === "newuser"
                ? "Le mot de passe ne peut être vide"
                : "Le nouveau mot de passe ne peut être vide",
            color: "error",
          },
        };
      });

    inputs.newPassword &&
      inputs.newPassword?.length < 8 &&
      setHelper((prev) => {
        return {
          ...prev,
          newPassword: {
            text: "Le nouveau mot de passe doit contenir au moins 8 caractères",
            color: "error",
          },
        };
      });

    inputs.newPassword &&
      !inputs.confirm &&
      setHelper((prev) => {
        return {
          ...prev,
          confirm: {
            text: "Confirmer le nouveau mot de passe",
            color: "error",
          },
        };
      });

    inputs.confirm &&
      inputs.newPassword &&
      inputs.confirm !== inputs.newPassword &&
      setHelper((prev) => {
        return {
          ...prev,
          confirm: {
            text: "Le nouveau mot de passe ne correspondent pas à ce que vous avez saisi ici",
            color: "error",
          },
        };
      });

    if (
      ((inputs.email ||
        inputs.firstname ||
        inputs.lastname ||
        inputs.username) &&
        !inputs.newPassword &&
        !userType) ||
      ((inputs.email ||
        inputs.firstname ||
        inputs.lastname ||
        inputs.username) &&
        !inputs.newPassword &&
        userType &&
        userType !== "newuser")
    ) {
      setLoading(true);
      updateUser(
        userType ? currentUser._id : user.currentUser._id,
        {
          email: inputs.email,
          firstname: inputs.firstname,
          lastname: inputs.lastname,
          username: inputs.username,
        },
        dispatch
      )
        .then(() => {
          setLoading(false);
          toast.success("Votre profile a été mise à jour !", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            transition: Flip,
          });

          if (userType === "admin" || userType === "superadmin") {
            setTimeout(() => {
              history.push("/manage/users");
            }, 2000);
          } else if (userType === "user") {
            setTimeout(() => {
              history.push("/users");
            }, 2000);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (
      inputs.email ||
      inputs.firstname ||
      inputs.lastname ||
      inputs.username ||
      (inputs.newPassword && inputs.newPassword === inputs.confirm)
    ) {
      setLoading(true);
      if (userType === "newuser") {
        addUser(
          {
            email: inputs.email,
            firstname: inputs.firstname,
            lastname: inputs.lastname,
            username: inputs.username,
            password: inputs.newPassword,
            role: "admin",
          },
          dispatch
        )
          .then(() => {
            setLoading(false);
            if (
              !isDuplicate ||
              !helper.newPassword.text ||
              !helper.confirm.text
            ) {
              toast.success("Le compte a été bien crée !", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                transition: Flip,
              });
              setTimeout(() => {
                history.push("/manage/users");
              }, 2000);
            }
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        updateUser(
          userType ? currentUser._id : user.currentUser._id,
          {
            email: inputs.email,
            firstname: inputs.firstname,
            lastname: inputs.lastname,
            username: inputs.username,
            password: inputs.newPassword,
          },
          dispatch
        )
          .then(() => {
            setLoading(false);
            if (!helper.newPassword.text || !helper.confirm.text) {
              toast.success("Votre profile a été mise à jour !", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                transition: Flip,
              });
            }
            if (userType === "admin") {
              setTimeout(() => {
                history.push("/manage/users");
              }, 2000);
            } else if (userType === "user") {
              setTimeout(() => {
                history.push("/users");
              }, 2000);
            }
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  const handleStoreClick = async (e) => {
    e.preventDefault();

    !inputs.storeEmail &&
      inputs.storeEmail !== undefined &&
      inputs.storeEmail !== stores[0]?.email &&
      setHelper((prev) => {
        return {
          ...prev,
          storeEmail: {
            text: "L'email de contact est obligatoire",
            color: "error",
          },
        };
      });

    !inputs.phones &&
      inputs.phones !== undefined &&
      inputs.phones !== stores[0]?.phones &&
      setHelper((prev) => {
        return {
          ...prev,
          businessPhone: {
            text: "Le numéro de téléphone transactionnel ne peut être vide",
            color: "error",
          },
        };
      });

    phones?.length === 0 &&
      phones !==
      stores[0].phones.map((phone) => {
        return { id: phone, text: phone };
      }) &&
      setHelper((prev) => {
        return {
          ...prev,
          phones: {
            text: "Les numéros principaux sont obligatoires",
            color: "error",
          },
        };
      });

    otherPhones?.length === 0 &&
      otherPhones !==
      stores[0].others.map((phone) => {
        return { id: phone, text: phone };
      }) &&
      setHelper((prev) => {
        return {
          ...prev,
          others: {
            text: "Au moins 2 numéro de téléphones sont requis",
            color: "error",
          },
        };
      });

    if (
      inputs.storeEmail ||
      inputs.businessPhone ||
      // if two arrays of objects is different
      !areArraysEqual(
        phones.map((phone) => phone.text),
        stores[0].phones
      ) ||
      !areArraysEqual(
        otherPhones.map((phone) => phone.text),
        stores[0].others
      )
    ) {
      setLoading(true);

      updateStore(
        stores[0]._id,
        {
          email: inputs.storeEmail,
          businessPhone: inputs.businessPhone,
          phones: phones.map((phone) => {
            return phone.id;
          }),
          others: otherPhones.map((phone) => {
            return phone.id;
          }),
        },
        dispatch
      )
        .then(() => {
          setLoading(false);
          toast.success("Les informations du site ont été mise à jour !", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            transition: Flip,
          });
          getStore(dispatch);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const areArraysEqual = (phones, store) => {
    if (phones?.length !== store?.length) return false;
    for (let i = phones?.length; i--;) {
      if (phones[i] !== store[i]) return false;
    }
    return true;
  };

  return (
    <Container className="settings">
      <Spacer y={1} />
      <Row align="center">
        <Text>
          <Text h1>
            {!userType && <span>Paramètres</span>}
            {userType === "newuser" && <span>Ajouter un administrateur</span>}
            {userType === "admin" && <span>Modifier un administrateur</span>}
            {userType === "user" && userId && <span>Modifier un compte client</span>}
          </Text>
        </Text>
      </Row>
      <Spacer y={2} />
      <Row>
        <Collapse.Group bordered style={{ flex: 1 }}>
          <Collapse
            title={userType ? "Profil" : "Mon profil"}
            subtitle={
              userType
                ? ""
                : "Changer mon mot de passe ou mettre à jour mon mot d'utilisateur"
            }
            initialExpanded
            showArrow={
              userType === "newuser" || userType === "admin" ? false : true
            }
          >
            <form className="settingsForm">
              <Row style={{ padding: "0 10px" }}>
                <Input
                  label="Email"
                  size="large"
                  width="100%"
                  name="email"
                  value={inputs.email}
                  shadow={false}
                  bordered
                  placeholder="lucie@laboratoireluxecosmetique.com"
                  initialValue={
                    userType === "newuser"
                      ? null
                      : userType === "admin" || userType === "user"
                        ? currentUser?.email
                        : user?.currentUser?.email
                  }
                  color={helper.email.color}
                  helperColor={helper.email.color}
                  helperText={helper.email.text}
                  onChange={handleChange}
                  disabled={
                    user?.currentUser?.role !== "superadmin" && !userType
                  }
                />
              </Row>
              <Spacer y={1.5} />
              <Row style={{ padding: "0 10px" }}>
                <Input
                  label="Nom"
                  size="large"
                  width="50%"
                  name="lastname"
                  value={inputs.lastname}
                  shadow={false}
                  bordered
                  placeholder="Dine"
                  initialValue={
                    userType === "newuser"
                      ? null
                      : userType === "admin" || userType === "user"
                        ? currentUser?.lastname
                        : user?.currentUser?.lastname
                  }
                  color={helper.lastname.color}
                  helperColor={helper.lastname.color}
                  helperText={helper.lastname.text}
                  onChange={handleChange}
                />
                <Spacer x={1} />
                <Input
                  label="Prénom"
                  size="large"
                  width="50%"
                  name="firstname"
                  value={inputs.firstname}
                  shadow={false}
                  bordered
                  placeholder="Lucie"
                  initialValue={
                    userType === "newuser"
                      ? null
                      : userType === "admin" || userType === "user"
                        ? currentUser?.firstname
                        : user?.currentUser?.firstname
                  }
                  color={helper.firstname.color}
                  helperColor={helper.firstname.color}
                  helperText={helper.firstname.text}
                  onChange={handleChange}
                />
              </Row>
              <Spacer y={1.5} />
              <Row style={{ padding: "0 10px" }}>
                <Input
                  label="Nom d'utilisateur"
                  size="large"
                  width="100%"
                  name="username"
                  value={inputs.username}
                  shadow={false}
                  bordered
                  placeholder="lucieda"
                  initialValue={
                    userType === "newuser"
                      ? null
                      : userType === "admin" || userType === "user"
                        ? currentUser?.username
                        : user?.currentUser?.username
                  }
                  color={helper.username.color}
                  helperColor={helper.username.color}
                  helperText={helper.username.text}
                  onChange={handleChange}
                />
              </Row>
              <Spacer y={1.5} />
              <Row style={{ padding: "0 10px" }}>
                <Input.Password
                  label={
                    userType === "newuser"
                      ? "Mot de passe"
                      : "Nouveau mot de passe"
                  }
                  size="large"
                  width="100%"
                  name="newPassword"
                  value={inputs.newPassword}
                  shadow={false}
                  bordered
                  color={helper.newPassword.color}
                  helperColor={helper.newPassword.color}
                  helperText={helper.newPassword.text}
                  onChange={handleChange}
                />
              </Row>
              <Spacer y={1.5} />
              <Row style={{ padding: "0 10px" }}>
                <Input.Password
                  label="Confirmer le mot de passe"
                  size="large"
                  width="100%"
                  name="confirm"
                  value={inputs.confirm}
                  shadow={false}
                  bordered
                  color={helper.confirm.color}
                  helperColor={helper.confirm.color}
                  helperText={helper.confirm.text}
                  onChange={handleChange}
                />
              </Row>
              <Spacer y={1.5} />
              <Row style={{ padding: "0 10px" }}>
                <Button
                  loaderType="spinner"
                  color="success"
                  {...(loading && { loading })}
                  onClick={handleClick}
                >
                  {userType !== "newuser" ? "Mettre à jour" : "Créer"}
                </Button>
              </Row>
            </form>
          </Collapse>
          {!userType && (
            <Collapse
              title="Informations du site"
              subtitle="Mettre à jour l'email et les numéros du site"
              disabled={user?.currentUser?.role !== "superadmin"}
              onClick={() => {
                user?.currentUser?.role !== "superadmin" &&
                  toast.error(
                    "Vous n'êtes pas autorisé à mettre à jour cette section",
                    {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      transition: Flip,
                    }
                  );
              }}
            >
              <Spacer y={1.5} />
              <Text h4>
                Ces informations reflètent l'email de contact du site web et de
                tous les numéros de téléphones mise à disposition des clients du
                sites. Les changements prennent immédiatement effect et seront
                visible sur le site web.
              </Text>
              <Spacer y={1.5} />
              <form className="settingsForm">
                <Row style={{ padding: "0 10px" }}>
                  <Input
                    label="Email de contact"
                    size="large"
                    width="100%"
                    name="storeEmail"
                    value={inputs.storeEmail}
                    shadow={false}
                    bordered
                    initialValue={stores[0]?.email}
                    color={helper.storeEmail.color}
                    helperColor={helper.storeEmail.color}
                    helperText={helper.storeEmail.text}
                    onChange={handleChange}
                  />
                </Row>
                <Spacer y={1.5} />
                <Row style={{ padding: "0 10px" }}>
                  <Input
                    label="Numéro de téléphone transactionel"
                    size="large"
                    width="100%"
                    name="businessPhone"
                    value={inputs.businessPhone}
                    shadow={false}
                    bordered
                    initialValue={stores[0]?.businessPhone}
                    color={helper.businessPhone.color}
                    helperColor={helper.businessPhone.color}
                    helperText={helper.businessPhone.text}
                    onChange={handleChange}
                  />
                </Row>
                <Spacer y={1.5} />
                <Row style={{ padding: "0 10px", flexWrap: "wrap" }}>
                  <label>Numéro de téléphones principaux</label>
                  <ReactTags
                    tags={phones}
                    name="phones"
                    handleDelete={handleDeletePhones}
                    handleAddition={handleAdditionPhones}
                    handleDrag={handleDragPhones}
                    delimiters={delimiters}
                    inputFieldPosition="top"
                    placeholder="Taper un numéro de téléphone suivi de + et son indicatif et appuyer sur valider"
                    handleInputChange={handlePhonesChange}
                  />
                  <Spacer y={1} />
                  <Text color={helper.phones.color} size={11.2}>
                    {helper.phones.text}
                  </Text>
                </Row>
                <Spacer y={1.5} />

                <Row style={{ padding: "0 10px", flexWrap: "wrap" }}>
                  <label>Autres</label>
                  <ReactTags
                    tags={otherPhones}
                    name="otherPhones"
                    handleDelete={handleDeleteOthersPhones}
                    handleAddition={handleAdditionOthersPhones}
                    handleDrag={handleDragOthersPhones}
                    delimiters={delimiters}
                    inputFieldPosition="top"
                    placeholder="Taper un numéro de téléphone suivi de + et son indicatif et appuyer sur valider"
                    handleInputChange={handleOthersPhonesChange}
                  />
                  <Spacer y={1} />
                  <Text color={helper.others.color} size={11.2}>
                    {helper.others.text}
                  </Text>
                </Row>
                <Spacer y={2} />
                <Row style={{ padding: "0 10px" }}>
                  <Button
                    loaderType="spinner"
                    color="success"
                    {...(loading && { loading })}
                    onClick={handleStoreClick}
                  >
                    Mettre à jour
                  </Button>
                </Row>
              </form>
            </Collapse>
          )}
        </Collapse.Group>
      </Row>
      <Spacer y={1} />
      <ToastContainer />
    </Container>
  );
}
