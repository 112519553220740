import "./widgetSm.css";
import { MdVisibility } from 'react-icons/md';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";

export default function WidgetSm() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await userRequest.get("users/?new=true");
        setUsers(response.data);
      } catch { }
    };
    getUsers();
  }, []);


  return (
    <div className="widgetSm">
      <div className="widgetSm__header">
        <span className="widgetSmTitle">Nouveaux clients</span>
      </div>
      <ul className="widgetSmList">
        {users.map((user) => (
          <li className="widgetSmListItem" key={user._id}>
            <img
              src={user.image || "https://crowd-literature.eu/wp-content/uploads/2015/01/no-avatar.gif"}
              alt=""
              className="widgetSmImg"
            />
            <div className="widgetSmUser">
              <span className="widgetSmUsername">{`${user.firstname} ${user.lastname}`}</span>
            </div>
            <Link className="widgetSmButton"
              to="/users"
            >
              <MdVisibility className="widgetSmIcon" />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}