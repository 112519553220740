import "./sales.css";
import {
  frFR, DataGrid, GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Row,
  Spacer,
  Text,
  Button,
  Progress,
} from "@nextui-org/react";
import "react-toastify/dist/ReactToastify.css";
import { userRequest } from "../../requestMethods";
const dayjs = require('dayjs');

export default function Sales() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [sales, setSales] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true);
    try {
      const res = await userRequest.get("/orders?delivered=true");
      setSales(res.data)
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }, [dispatch]);

  const columns = [
    {
      field: "orderNumber",
      headerName: "N°",
      width: 100,
    },
    {
      field: "updatedAt",
      headerName: "Date",
      width: 110,
      renderCell: (params) => {
        return (
          <Text>{dayjs(params.row.updatedAt).format("DD/MM/YYYY")}</Text>
        );
      },
    },
    {
      field: "userId",
      headerName: "Client",
      width: 300,
      valueFormatter: (params) => {
        return (
          params.row.userId.lastname + " " + params.row.userId.firstname
        );
      },
      renderCell: (params) => {
        return (
          <Text>{params.row.userId.lastname}{" "}{params.row.userId.firstname}</Text>
        );
      },
    },
    {
      field: "products",
      headerName: "Products",
      width: 300,
      valueFormatter: (params) => {
        return (
          params.row.products.map((product, i) => {
            return (
              i !== params.row.products.length - 1 ? product.productId.name + ", " : product.productId.name
            );
          })
        );
      },
      renderCell: (params) => {
        return (
          <>
            {params.row.products
              .slice(0, 1)
              .map((product) => {
                return (
                  <div className="saleItem">
                    <Text>{product.productId.name}</Text>
                  </div>
                );
              })}
            {params.row.products.length > 1 && (
              <div className="saleItem">
                <Text>, ...</Text>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Nbre produits",
      width: 200,
      valueFormatter: (params) => {
        return params.row.products.length;
      },
      renderCell: (params) => {
        return (
          <>
            {params.row.products.length}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      valueFormatter: (params) => {
        return (
          "Livré"
        )
      },
      renderCell: (params) => {
        return (
          <>
            {params.row.status === "delivered" && (
              <Text color="success">Livré</Text>
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Button flat auto
            onClick={() => {
              history.push(`/orders/order/${format(params.row.orderNumber)}`);
            }}>
            Détails
          </Button>
        );
      }
    }
  ];

  const format = (str) => {
    return str.replace(/^#/, '');
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <Container className="sales">
      <Spacer y={1} />
      <Row align="center" justify="space-between">
        <Text h1>Historique des Ventes</Text>
      </Row>
      <Spacer y={1} />
      {isLoading && (
        <Progress
          indeterminated
          color="secondary"
          status="secondary"
          size="mini"
        />
      )}
      <DataGrid
        localeText={frFR.props.MuiDataGrid.localeText}
        rows={sales}
        disableSelectionOnClick
        columns={columns}
        getRowId={(row) => row._id}
        pageSize={8}
        columnBuffer={8}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </Container>
  );
}
