import "./featuredInfo.css";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";

export default function FeaturedInfo() {
  const [income, setIncome] = useState([]);
  const [monthlyOrders, setMonthlyOrders] = useState([]);
  const [monthlySales, setMonthlySales] = useState([]);
  const [monthlyOrdersPercentage, setMonthlyOrdersPercentage] = useState(0);
  const [monthlySalesPercentage, setMonthlySalesPercentage] = useState(0);

  useEffect(() => {
    const getMonthyOrders = async () => {
      try {
        const res = await userRequest.get("orders/average?delivered=true");
        setMonthlySales(res.data);
        setMonthlySalesPercentage((res.data[1].total * 100) / res.data[0].total - 100);
      } catch { }
    };

    const getMonthySales = async () => {
      try {
        const res = await userRequest.get("orders/average?delivered=false");
        setMonthlyOrders(res.data);
        setMonthlyOrdersPercentage((res.data[1].total * 100) / res.data[0].total - 100);
      } catch { }
    };
    getMonthyOrders();
    getMonthySales();
  }, []);

  return (
    <div className="featured">
      <div className="featuredItem">
        <span className="featuredTitle">Ventes</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{monthlySales[0]?.total || 0}</span>
          <span className="featuredMoneyRate">
            {Math.floor(monthlySalesPercentage)}{" "}%
            {monthlySalesPercentage < 0 ? (
              <BsArrowDownShort className="featuredIcon negative" />
            ) : (
              <BsArrowUpShort className="featuredIcon" />
            )}
          </span>
        </div>
        <span className="featuredSub">Par rapport au mois dernier</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Commandes</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{monthlyOrders[0]?.total || 0}</span>
          <span className="featuredMoneyRate">
            {Math.floor(monthlyOrdersPercentage)}{" "}%
            {monthlyOrdersPercentage < 0 ? (
              <BsArrowDownShort className="featuredIcon negative" />
            ) : (
              <BsArrowUpShort className="featuredIcon" />
            )}
          </span>
        </div>
        <span className="featuredSub">Par rapport au mois dernier</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Revenue</span>
        <div className="featuredMoneyContainer">
          Non disponible
          <br />
          <br />
        </div>
        <span className="featuredSub">Par rapport au mois dernier</span>
      </div>
    </div>
  );
}
