import { publicRequest, userRequest } from "../requestMethods";
import {
  getProductFailure,
  getProductStart,
  getProductSuccess,
  deleteProductFailure,
  deleteProductStart,
  deleteProductSuccess,
  updateProductFailure,
  updateProductStart,
  updateProductSuccess,
  addProductFailure,
  addProductStart,
  addProductSuccess,
  getProductsSuccess,
} from "./productRedux";
import {
  addCategoryDuplicateFailure,
  addCategoryFailure,
  addCategoryStart,
  addCategorySuccess,
  deleteCategoryFailure,
  deleteCategoryStart,
  deleteCategorySuccess,
  getCategoriesSuccess,
  getCategoryFailure,
  getCategoryStart,
  getCategorySuccess,
  updateCategoryFailure,
  updateCategoryStart,
  updateCategorySuccess,
} from "./categoryRedux";
import {
  addRangeDuplicateFailure,
  addRangeFailure,
  addRangeStart,
  addRangeSuccess,
  deleteRangeFailure,
  deleteRangeStart,
  deleteRangeSuccess,
  getRangeFailure,
  getRangeStart,
  getRangeSuccess,
  updateRangeFailure,
  updateRangeStart,
  updateRangeSuccess,
  getRangesSuccess,
} from "./rangeRedux";
import {
  uploadAssetFailure,
  uploadAssetStart,
  uploadAssetSuccess,
  removeAssetFailure,
  removeAssetStart,
  removeAssetSuccess,
} from "./assetRedux";
import {
  getStoreFailure,
  getStoreStart,
  getStoreSuccess,
  updateStoreFailure,
  updateStoreStart,
  updateStoreSuccess,
} from "./storeRedux";
import {
  addUserDuplicateFailure,
  addUserFailure,
  addUserStart,
  addUserSuccess,
  deleteUserStart,
  deleteUserSuccess,
  getUserExpiredFailure,
  getUserFailure,
  getUserStart,
  getUserSuccess,
  loginFailure,
  loginStart,
  loginSuccess,
  updateUserFailure,
  updateUserStart,
  updateUserSuccess,
} from "./userRedux";
import {
  getUsersFailure,
  getUsersStart,
  getUsersSuccess,
  updateUsersStart,
  updateUsersFailure,
  updateUsersSuccess,
} from "./usersRedux";
import { getOrderFailure, getOrderStart, getOrderSuccess, updateOrderFailure, updateOrderStart, updateOrderSuccess } from "./orderRedux";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    dispatch(loginSuccess(res.data));
  } catch (err) {
    dispatch(loginFailure());
  }
};

export const addUser = async (user, dispatch) => {
  dispatch(addUserStart);
  try {
    const res = await userRequest.post("/users", user);
    dispatch(addUserSuccess(res.data));
  } catch (err) {
    if (err.message.includes("409")) {
      dispatch(addUserDuplicateFailure(err));
    } else {
      dispatch(addUserFailure());
    }
  }
};

export const getUsers = async (dispatch) => {
  dispatch(getUsersStart());
  try {
    const res = await userRequest.get("/users");
    dispatch(getUsersSuccess(res.data));
  } catch (err) {
    dispatch(getUsersFailure());
  }
};

export const getNewUsers = async (dispatch) => {
  dispatch(getUsersStart());
  try {
    const res = await userRequest.get("/users/?new=true");
    dispatch(getUsersSuccess(res.data));
  } catch (err) {
    dispatch(getUsersFailure());
  }
};

export const getAdminUsers = async (dispatch) => {
  dispatch(getUsersStart());
  try {
    const res = await userRequest.get("/users/?admin=true");
    dispatch(getUsersSuccess(res.data));
  } catch (err) {
    dispatch(getUsersFailure());
  }
};

export const getUser = async (id, dispatch) => {
  dispatch(getUserStart());
  try {
    const res = await userRequest.get(`/users/find/${id}`);
    dispatch(getUserSuccess(res.data));
  } catch (err) {
    if (err.message.includes("403")) {
      dispatch(getUserExpiredFailure());
    } else {
      dispatch(getUserFailure());
    }
  }
};

export const getCurrentUser = (id) => {
  return userRequest.get(`/users/find/${id}`);
};

export const updateUsersUser = async (id, user, dispatch) => {
  dispatch(updateUsersStart());
  try {
    await userRequest.put(`/users/${id}`, user);
    dispatch(updateUsersSuccess());
    getUsers(dispatch);
  } catch (err) {
    dispatch(updateUsersFailure());
  }
};

export const updateUser = async (id, user, dispatch) => {
  dispatch(updateUserStart());
  try {
    const res = await userRequest.put(`/users/${id}`, user);
    dispatch(updateUserSuccess(res.data));
  } catch (err) {
    dispatch(updateUserFailure());
  }
};

export const deleteUser = async (id, dispatch) => {
  dispatch(deleteUserStart());
  try {
    await userRequest.delete(`/products/${id}`);
    dispatch(deleteUserSuccess(id));
  } catch (err) {
    dispatch(deleteUserFailure());
  }
};

export const getProducts = async (dispatch) => {
  dispatch(getProductStart());
  try {
    const res = await publicRequest.get("/products/all?sort=desc");
    dispatch(getProductsSuccess(res.data));
  } catch (err) {
    dispatch(getProductFailure());
  }
};

export const getProduct = async (id, dispatch) => {
  dispatch(getProductStart());
  try {
    const res = await publicRequest.get(`/products/find/${id}`);
    dispatch(getProductSuccess(res.data));
  } catch (err) {
    dispatch(getProductFailure());
  }
};

export const deleteProduct = async (
  id,
  inputsCategories,
  inputsRanges,
  dispatch
) => {
  dispatch(deleteProductStart());
  try {
    await userRequest.delete(`/products/${id}`);
    inputsCategories.forEach((category) => {
      updateProductFromCategory(id, category._id, dispatch);
    });
    updateProductFromRange(id, inputsRanges._id, dispatch);
    dispatch(deleteProductSuccess(id));
  } catch (err) {
    dispatch(deleteProductFailure());
  }
};

export const updateProductFromCategory = async (id, categoryId, dispatch) => {
  dispatch(updateCategoryStart());
  try {
    await userRequest.put(`/categories/remove/${categoryId}/product/${id}`);
    dispatch(updateCategorySuccess(id));
  } catch (err) {
    dispatch(updateCategoryFailure());
  }
};

export const updateProductFromRange = async (id, rangeId, dispatch) => {
  dispatch(updateRangeStart());
  try {
    await userRequest.put(`/ranges/remove/${rangeId}/product/${id}`);
    dispatch(updateRangeSuccess(id));
  } catch (err) {
    dispatch(updateRangeFailure());
  }
};

export const publishProduct = async (id, product, dispatch) => {
  dispatch(updateProductStart());
  try {
    const res = await userRequest.put(`/products/${id}`, product);
    dispatch(updateProductSuccess(res.data));
  } catch (err) {
    dispatch(updateProductFailure());
  }
};

export const updateProduct = async (
  id,
  product,
  dispatch
) => {
  dispatch(updateProductStart());
  try {
    const res = await userRequest.put(`/products/${id}`, product);
    dispatch(updateProductSuccess(res.data));
  } catch (err) {
    dispatch(updateProductFailure());
  }
};

export const addProduct = async (
  product,
  categories,
  inputsCategories,
  ranges,
  inputsRanges,
  dispatch
) => {
  dispatch(addProductStart());
  try {
    const res = await userRequest.post(`/products`, product);
    categories.forEach((category) => {
      if (inputsCategories.indexOf(category._id) !== -1) {
        updateCategory(
          category._id,
          {
            products: [...category.products, res.data._id],
          },
          dispatch
        );
      }
    });
    ranges.forEach((ranges) => {
      if (inputsRanges.indexOf(ranges._id) !== -1) {
        updateRange(
          ranges._id,
          {
            products: [...ranges.products, res.data._id],
          },
          dispatch
        );
      }
    });
    dispatch(addProductSuccess(res.data));
  } catch (err) {
    dispatch(addProductFailure());
  }
};

export const getCategories = async (dispatch) => {
  dispatch(getCategoryStart());
  try {
    const res = await publicRequest.get("/categories/all?sort=desc");
    dispatch(getCategoriesSuccess(res.data));
  } catch (err) {
    dispatch(getCategoryFailure());
  }
};

export const getCategory = async (id, dispatch) => {
  dispatch(getCategoryStart());
  try {
    const res = await publicRequest.get(`/categories/find/${id}`);
    dispatch(getCategorySuccess(res.data));
  } catch (err) {
    dispatch(getCategoryFailure());
  }
};

export const updateCategory = async (id, category, dispatch) => {
  dispatch(getCategoryStart());
  try {
    const res = await userRequest.put(`/categories/${id}`, category);
    dispatch(getCategorySuccess(res.data));
  } catch (err) {
    dispatch(getCategoryFailure());
  }
};

export const deleteCategory = async (id, dispatch) => {
  dispatch(deleteCategoryStart());
  try {
    await userRequest.delete(`/categories/${id}`);
    dispatch(deleteCategorySuccess(id));
  } catch (err) {
    dispatch(deleteCategoryFailure());
  }
};

export const addCategory = async (category, dispatch) => {
  dispatch(addCategoryStart());
  try {
    const res = await userRequest.post(`/categories`, category);
    dispatch(addCategorySuccess(res.data));
  } catch (err) {
    if (err.message.includes("409")) {
      dispatch(addCategoryDuplicateFailure(err));
    } else {
      dispatch(addCategoryFailure());
    }
  }
};

export const getRanges = async (dispatch) => {
  dispatch(getRangeStart());
  try {
    const res = await publicRequest.get("/ranges/all?sort=desc");
    dispatch(getRangesSuccess(res.data));
  } catch (err) {
    dispatch(getRangeFailure());
  }
};

export const getRange = async (id, dispatch) => {
  dispatch(getRangeStart());
  try {
    const res = await publicRequest.get(`/ranges/find/${id}`);
    dispatch(getRangeSuccess(res.data));
  } catch (err) {
    dispatch(getRangeFailure());
  }
};

export const deleteRange = async (id, dispatch) => {
  dispatch(deleteRangeStart());
  try {
    await userRequest.delete(`/ranges/${id}`);
    dispatch(deleteRangeSuccess(id));
  } catch (err) {
    dispatch(deleteRangeFailure());
  }
};

export const updateRange = async (id, range, dispatch) => {
  dispatch(updateRangeStart());
  try {
    res = await userRequest.put(`/ranges/${id}`, range);
    dispatch(updateRangeSuccess(res.data));
  } catch (err) {
    dispatch(updateRangeFailure());
  }
};

export const addRange = async (range, dispatch) => {
  dispatch(addRangeStart());
  try {
    const res = await userRequest.post(`/ranges`, range);
    dispatch(addRangeSuccess(res.data));
  } catch (err) {
    if (err.message.includes("409")) {
      dispatch(addRangeDuplicateFailure(err));
    } else {
      dispatch(addRangeFailure());
    }
  }
};

export const uploadAsset = async (file, dispatch) => {
  dispatch(uploadAssetStart());
  try {
    await userRequest.post(`/upload/save`, { file: file });
    dispatch(uploadAssetSuccess(res.data));
  } catch (err) {
    dispatch(uploadAssetFailure());
  }
};

export const removeAsset = async (file, dispatch) => {
  dispatch(removeAssetStart());
  try {
    await userRequest.post(`/upload/delete/`, { file: file });
    dispatch(removeAssetSuccess(res.data));
  } catch (err) {
    dispatch(removeAssetFailure());
  }
};

export const getSettings = async (dispatch) => {
  dispatch(getSettingsStart());
  try {
    const res = await userRequest.get("/store");
    dispatch(getSettingsSuccess(res.data));
  } catch (err) {
    dispatch(getSettingsFailure());
  }
};

export const getStore = async (dispatch) => {
  dispatch(getStoreStart());
  try {
    const res = await publicRequest.get("/store");
    dispatch(getStoreSuccess(res.data));
  } catch (err) {
    dispatch(getStoreFailure());
  }
};

export const updateStore = async (id, store, dispatch) => {
  dispatch(updateStoreStart());
  try {
    const res = await userRequest.put(`/store/${id}`, store);
    dispatch(updateStoreSuccess(res.data));
  } catch (err) {
    dispatch(updateStoreFailure());
  }
};

export const getOrders = async (dispatch) => {
  dispatch(getOrderStart());
  try {
    const res = await userRequest.get("/orders?delivered=false");
    dispatch(getOrderSuccess(res.data));
  } catch (err) {
    dispatch(getOrderFailure());
  }
}

export const updateOrder = async (id, order, dispatch) => {
  dispatch(updateOrderStart());
  try {
    const res = await userRequest.put(`/orders/${id}`, order);
    dispatch(updateOrderSuccess(res.data));
    if (order.status === "delivered") {
      dispatch(getOrders(dispatch));
    }
  } catch (err) {
    dispatch(updateOrderFailure());
  }
}
