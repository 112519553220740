import { createSlice } from "@reduxjs/toolkit";

export const rangeSlice = createSlice({
  name: "range",
  initialState: {
    ranges: [],
    range: null,
    isFetching: false,
    error: false,
    isDuplicate: false,
  },
  reducers: {
    resetRange: (state) => {
      state.isFetching = false;
      state.error = false;
      state.isDuplicate = false;
    },
    resetCurrentRange: (state) => {
      state.range = null;
    },
    //GET ALL
    getRangeStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getRangesSuccess: (state, action) => {
      state.isFetching = false;
      state.ranges = action.payload;
    },
    getRangeSuccess: (state, action) => {
      state.isFetching = false;
      state.range = action.payload;
    },
    getRangeFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    addRangeDuplicateFailure: (state) => {
      state.isFetching = false;
      state.error = true;
      state.isDuplicate = true;
    },
    //DELETE
    deleteRangeStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteRangeSuccess: (state, action) => {
      state.isFetching = false;
      state.ranges.splice(
        state.ranges.findIndex((item) => item._id === action.payload),
        1
      );
    },
    deleteRangeFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE
    updateRangeStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateRangeSuccess: (state, action) => {
      state.isFetching = false;
      state.ranges[
        state.ranges.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.range;
    },
    updateRangeFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    addRangeStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addRangeSuccess: (state, action) => {
      state.isFetching = false;
      state.ranges.push(action.payload);
    },
    addRangeFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  resetRange,
  resetCurrentRange,
  getRangeStart,
  getRangeSuccess,
  getRangesSuccess,
  getRangeFailure,
  deleteRangeStart,
  deleteRangeSuccess,
  deleteRangeFailure,
  updateRangeStart,
  updateRangeSuccess,
  updateRangeFailure,
  addRangeStart,
  addRangeSuccess,
  addRangeFailure,
  addRangeDuplicateFailure,
} = rangeSlice.actions;

export default rangeSlice.reducer;
